import { Path, buildPath } from '~/routes/paths';

export type CustomerAssetTab = { path: string; label: string };

export type CustomerAssetTabs = Record<
  'overview' | 'logs' | 'reports' | 'graph' | string,
  CustomerAssetTab
>;

export function getTabs(id: string): CustomerAssetTabs {
  return {
    graph: {
      path: buildPath(Path.CUSTOMER_ASSET, { id }),
      label: 'graph',
    },
    overview: {
      path: buildPath(Path.COMMON_CUSTOMER_ASSET, { tab: 'overview', id }),
      label: 'overview',
    },
    logs: {
      path: buildPath(Path.COMMON_CUSTOMER_ASSET, { tab: 'logs', id }),
      label: 'logs',
    },
    reports: {
      path: buildPath(Path.COMMON_CUSTOMER_ASSET, { tab: 'reports', id }),
      label: 'reports',
    },
  };
}
